import React from "react"

import { withAccountInvalid } from "./withAccountInvalid"
import { FormHeading, FormNote } from "../../Styled/Form"
import { StyledContainer } from "./AccountInvalidStyles"

export const AccountInvalid = withAccountInvalid(
  ({ title, content }): JSX.Element => (
    <StyledContainer>
      <FormHeading>{title}</FormHeading>
      <FormNote>{content}</FormNote>
    </StyledContainer>
  )
)
